<template>
    <a v-if="!isExternalLink"
       class="navigation-item-link"
       :href="linkPath"
       :aria-label="menuItem.name">
        <slot>
            {{ menuItem.name }}
        </slot>
    </a>

    <a v-else class="navigation-item-link" ref="link" :href="linkPath" :target="target"
              :aria-label="ariaLabel">
        <slot>
            {{ menuItem.name }}
        </slot>
    </a>
</template>

<script>
    export default {
        name: "NavigationItemLink",
        props: {
            menuItem: {
                type: Object
            }
        },
        data () {
            return {
                isExternalLink: true,
                isBlogLink: false,
            }
        },
        computed: {
            linkPath () {
                let result = ''

                switch (this.menuItem.menu_itemable_type) {
                    case 'Product':
                        result = `/products/${this.menuItem.link}`
                        break;
                    case 'Category':
                        result = `/categories/${this.menuItem.link}`
                        break;
                    case 'Page':
                        result = `/pages/${this.menuItem.link}`
                        break;
                    case 'Supplier':
                        result = `/brands/${this.menuItem.link}`
                        break;
                    case 'Collection':
                        result = `/collections/${this.menuItem.link}`
                        break;
                    default:
                        if (process.server) {
                            result = this.menuItem.link
                        } else {
                            let originPattern = `^(${location.origin}|${location.hostname})`.replace(/www\./g, '(www\\.)?')
                            originPattern = originPattern.replace(/www\./g, '(www\\.)?')

                            let originRegExp = new RegExp(originPattern)
                            result = this.menuItem.link.replace(originRegExp, '')
                        }
                }

                return result
            },
            ariaLabel () {
                return (this.isExternalLink && !this.isBlogLink)
                    ? `Open ${this.menuItem.name} page in a new tab`
                    : `Open ${this.menuItem.name} page`
            },
            target () {
                return (this.isExternalLink && !this.isBlogLink) ? '_blank' : '_self'
            }
        },
        watch: {
            linkPath () {
                this.setIsExternalLink()
                this.setIsBlogLink()
            }
        },
        mounted () {
            this.setIsExternalLink()
            this.setIsBlogLink()
        },
        methods: {
            setIsExternalLink () {
                this.$nextTick(() => {
                    if (this.$refs.link) {
                        const { hostname } = this.$refs.link
                        this.isExternalLink = (location.hostname !== hostname && hostname.length)
                    }
                })
            },
            setIsBlogLink () {
              this.$nextTick(() => {
                if (this.$refs.link) {
                        const { hostname } = this.$refs.link
                        const links = ['blog.scoutandnimble', 'blog-staging.scoutandnimble', `${hostname}/blog`]
                        this.isBlogLink = links.some(link => this.menuItem?.link?.includes(link))
                    }
                })

            }
        }

    }
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/variables.scss';

.navigation-item-link,
.navigation-item-link:visited,
.navigation-item-link:hover,
.navigation-item-link:active {
  text-decoration: none;
  font-family: OpenSans;
  font-size: 1.4rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.7px;
  text-align: left;
}
</style>
